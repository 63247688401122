import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"padding":"0 12px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NpcWallet = _resolveComponent("NpcWallet")
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")
  const _component_MerchantWallet = _resolveComponent("MerchantWallet")
  const _component_a_tabs = _resolveComponent("a-tabs")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_a_tabs, {
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeKey = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tab_pane, {
          key: "1",
          tab: "陪玩钱包交易明细"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NpcWallet)
          ]),
          _: 1
        }),
        _createVNode(_component_a_tab_pane, {
          key: "2",
          tab: "商家钱包交易明细"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MerchantWallet)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["activeKey"])
  ]))
}