export const tableModel = [
  { title: '商家图片', key: 'HeadImg', width: '100px',slots: { customRender: 'img' } },
  { title: '商家名称', key: 'ShopName',width: '240px', dataIndex: 'ShopName' },
  { title: '账号', key: 'LoginMobile',width: '180px', dataIndex: 'LoginMobile' },
  { title: '商家地址', key: 'Address',width: '380px', slots: { customRender: 'Address' } },
  { title: '商家pc链接', key: 'link', width: '380px',slots: { customRender: 'link' } },
  { title: '提成比例', key: 'CommissionRatio', slots: { customRender: 'CommissionRatio' }, width: '100px' },
  { title: '钱包余额', key: 'WalletBalance', slots: { customRender: 'WalletBalance' }, width: '100px' },
  { title: '操作', key: 'action', slots: { customRender: 'action' }, width: '280px', fixed: 'right' },
];

export const searchModel = {
  ShopCode: '',
  ShopName: '',
  PageIndex: 1,
  PageSize: 10,
};

export const shopInfoModel = {
  Address: '',
  City: '',
  CommissionRatio: 10, //商家比例
  County: '',
  HeadImg: '',
  Id: 0,
  Location: '',
  Province: '',
  ShopCode: '',
  WalletBalance: null,
  InsertDate: '',
  ShopName: '',
  LoginMobile: '',
  LoginPassword: '',
};

export const walletTableModel = [
  { title: '交易状态', key: 'DetailStatusStr', dataIndex: 'DetailStatusStr' , width:'180px' },
  { title: '收支类型', key: 'AmountTypeStr', dataIndex: 'AmountTypeStr' , width:'100px' },
  { title: '标题', key: 'DetailTitle', dataIndex: 'DetailTitle' , width:'100px' },
  { title: '备注', key: 'Remark', dataIndex: 'Remark' , width:'420px' },
  { title: '交易成功时间', key: 'SuccessTime', dataIndex: 'SuccessTime' , width:'180px' },
  { title: '金额', key: 'link', dataIndex: 'Amount', slots: { customRender: 'amount' }, width:'100px'  },
  { title: '钱包类型', key: 'WalletTypeStr', dataIndex: 'WalletTypeStr', width:'180px'  },
];

export const walletSearchModel = {
  DetailStatus: '',
  AmountType: '',
  BusinessId: '',
  ShopId: 0,
  PageIndex: 1,
  PageSize: 10,
};

export const walletDetailStatus = [
  { code: '', value: '全部状态' },
  { code: 0, value: '交易中' },
  { code: 1, value: '交易失败' },
  { code: 2, value: '交易成功' },
];

export const walletAmountType = [
  { code: '', value: '全部类型' },
  { code: 0, value: '收入' },
  { code: 1, value: '支出' },
];
