
import { defineComponent } from 'vue';
import { useUser } from './index';
export default defineComponent({
  props: {
    couponId: {
      type: [String, Number],
      default: '',
    },
    showCouponId: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, cxt) {
    return { ...useUser(props, cxt) };
  },
});
