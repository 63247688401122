import { reactive, ref } from 'vue';
import _ from 'lodash';
import { walletTableModel, walletSearchModel, walletDetailStatus, walletAmountType } from '@/data/modules/merchant/list';
import { walletInfo } from '@/api/merchant';
import { awaitWrap } from '@/util/index';
import { useInitRoute } from '@/util/router';
//表单hooks
export function useTable(): any {
  const { queryParams } = useInitRoute();
  //搜索数据
  const searchRef = reactive(_.clone(walletSearchModel));

  //重置表单
  function handleReset() {
    _.assignIn(searchRef, walletSearchModel);
  }

  //分页数据
  const pagination = reactive({
    current: 1,
    total: 10,
  });

  //表格数据
  const tableColumns = walletTableModel;
  const tableSource = ref([]);
  const tableLoading = ref(false);

  searchRef.ShopId = queryParams('id');
  // 获取列表
  async function getList() {
    tableLoading.value = true;
    const { code, data } = await awaitWrap(walletInfo(searchRef));
    tableLoading.value = false;
    if (code === 200) {
      tableSource.value = data.data.DataList;
      pagination.total = data.data.RecordCount;
    }
  }

  //分页操作
  function handlePageChange(val: any) {
    pagination.current = val;
    searchRef.PageIndex = val;
    getList();
  }

  getList();

  return {
    walletDetailStatus,
    walletAmountType,
    tableLoading,
    tableColumns,
    tableSource,
    pagination,
    searchRef,
    handlePageChange,
    getList,
    handleReset,
  };
}
