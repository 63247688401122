import request from './axios';

//添加修改优惠券
export const addOrEditCoupon = (data: any): Promise<any> => {
  return request({
    url: '/coupon/rule/add',
    method: 'post',
    data: data,
  });
};

//优惠券列表
export const couponList = (params: any): Promise<any> => {
  return request({
    url: '/coupon/rule/list',
    method: 'get',
    params: params,
  });
};

//优惠券列表
export const couponDetail = (id: any): Promise<any> => {
  return request({
    url: `/coupon/rule/${id}/detail`,
    method: 'get',
  });
};

//优惠券发放给用户
export const giveCouponUser = (id: any, data: any): Promise<any> => {
  return request({
    url: `/coupon/rule/${id}/user`,
    method: 'put',
    data: data,
  });
};

//优惠券发放给商家
export const giveCouponShop = (id: any, data: any): Promise<any> => {
  return request({
    url: `/coupon/rule/${id}/shop`,
    method: 'put',
    data: data,
  });
};


//优惠券用户领取列表
export const couponUserList = (params:any): Promise<any> => {
  return request({
    url: `/coupon/user/list`,
    method: 'get',
    params:params
  });
};

//优惠券商家领取列表
export const couponShopList = (params:any): Promise<any> => {
  return request({
    url: `/coupon/shop/list`,
    method: 'get',
    params:params
  });
};


