import { reactive, computed, ref, getCurrentInstance } from 'vue';
import _ from 'lodash';
import { tableModel, searchModel, shopInfoModel } from '@/data/modules/merchant/list';
import { shopAdd, shopList, shopDetail, shopDel } from '@/api/merchant';
import { awaitWrap } from '@/util/index';
import { ModalError } from '@/util/message';
import { message } from 'ant-design-vue';
import { useInitRouter } from '@/util/router';

import Map from '@/components/map/map';

//表单hooks
export function useTable(): any {
  const { routerPush } = useInitRouter();

  //搜索数据
  const searchRef = reactive(_.clone(searchModel));

  //重置表单
  function handleReset() {
    _.assignIn(searchRef, searchModel);
  }

  //分页数据
  const pagination = reactive({
    current: 1,
    total: 10,
  });

  //表格数据
  const tableColumns = tableModel;
  const tableSource = ref([]);
  const tableLoading = ref(false);

  // 获取列表
  async function getList() {
    tableLoading.value = true;
    const { code, data } = await awaitWrap(shopList(searchRef));
    tableLoading.value = false;
    if (code === 200) {
      tableSource.value = data.data.DataList;
      pagination.total = data.data.RecordCount;
    }
  }

  //分页操作
  function handlePageChange(val: any) {
    pagination.current = val;
    searchRef.PageIndex = val;
    getList();
  }

  getList();

  function handleDel(el: any) {
    ModalError('你确定要删除', async () => {
      const { code } = await awaitWrap(shopDel(el.Id));
      return new Promise((resolve, reject) => {
        if (code === 200) {
          message.success('删除成功');
          getList();
          resolve('');
        } else {
          reject();
        }
      });
    });
  }

  function handleWalletInfo(Id: string): void {
    routerPush({
      path: '/merchant/wallet/detail',
      query: {
        id: Id,
      },
    });
  }
  return {
    tableLoading,
    tableColumns,
    tableSource,
    pagination,
    searchRef,
    handleDel,
    handlePageChange,
    getList,
    handleReset,
    handleWalletInfo,
  };
}

// 商家shophooks
export function shopTable(): any {
  const aMap = new Map({ plugins: ['AMap.PlaceSearch', 'AMap.Geocoder'] }, 'container');

  const { proxy }: any = getCurrentInstance();
  const formRef = ref();

  //弹出层控制
  const showModel = ref(false);

  //验证规则
  const roleInfo = {
    ShopName: [{ required: true, message: '请输入商家名称', trigger: 'change' }],
    Address: [{ required: true, message: '请输入商家地址', trigger: 'change' }],
    LoginMobile: [{ required: true, message: '请输入商家账号', trigger: 'change' }],
    LoginPassword: [{ required: true, message: '请输入商家密码', trigger: 'change' }],
  };

  //添加信息
  const info = reactive(_.clone(shopInfoModel));

  const imageComputed = computed({
    get() {
      return info.HeadImg ? info.HeadImg.split('|') : [];
    },
    set(val: Array<string>) {
      info.HeadImg = val.join('|');
    },
  });

  //获取地址信息
  function handleBlurAddress() {
    aMap
      .getLocation(addressComputed.value)
      .then((ref: any) => {
        const addr = ref.addressComponent;
        // ${addr.city}${addr.district}
        info.Address = addressComputed.value.replace(`${addr.province}`, '').replace(`${addr.city}`, '').replace(`${addr.district}`, '');
        info.Province = addr.province;
        info.City = addr.city;
        info.County = addr.district;
        info.Location = `${ref.location.lng},${ref.location.lat}`;
      })
      .catch(() => {
        info.Address = '';
        message.warning('地址转化失败！请重新填写');
      });
  }
  const addressComputed = computed({
    get() {
      return info.Province + info.City + info.County + info.Address;
    },
    set(val: string) {
      info.Province = '';
      info.City = '';
      info.County = '';
      info.Address = val;
    },
  });

  //查看详情
  async function handleDetail(el: any) {
    const { code, data } = await awaitWrap(shopDetail(el.Id));
    if (code === 200) {
      _.assignIn(info, data.data);
      showModel.value = true;
    }
  }

  //添加数据
  function handleAddShow(): void {
    _.assignIn(info, shopInfoModel);
    showModel.value = true;
  }

  //添加修改
  async function haeldAddShop(callback: () => void) {
    const validate = await awaitWrap(formRef.value.validate());
    // const validate = await awaitWrap(formRef.value.validate());

    if (validate.code === 200) {
      const ret = await awaitWrap(shopAdd(info));
      if (ret.code === 200) {
        message.success('编辑成功');
        showModel.value = false;
        proxy.getList();
      }
    }
    callback();
  }

  return {
    formRef,
    showModel,
    roleInfo,
    info,
    addressComputed,
    imageComputed,
    handleDetail,
    handleAddShow,
    haeldAddShop,
    handleBlurAddress,
  };
}
