
import { defineComponent } from 'vue';
import { useGiveUser } from '../list';
export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    couponId: {
      type: [String, Number],
      default: 'false',
    },
  },
  setup(props, cxt) {
    return { ...useGiveUser(props, cxt) };
  },
});
