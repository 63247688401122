
// 0:提现申请中，1:审核拒绝，2:审核通过，3:提现失败，4:提现完成
export const walletReviewType = [
  { code: 0, value: '提现申请中' },
  { code: 1, value: '审核拒绝' },
  { code: 2, value: '审核通过' },
  { code: 3, value: '提现失败' },
  { code: 4, value: '提现完成' },
];



export const applyListSearchModel = {
  WithdrawStatus: '',
  WithdrawMethod: '',
  PageIndex: 1,
  PageSize: 10,
};



export const applyListTableModel = [
  { title: '提现商家', key: 'UserName', dataIndex: 'UserName'},
  { title: '钱包类型', key: 'WalletTypeStr', dataIndex: 'WalletTypeStr'},
  { title: '审核状态', key: 'WithdrawStatusStr', dataIndex: 'WithdrawStatusStr',slots: { customRender: 'statusStr' }},
  { title: '提现方式', key: 'WithdrawMethodStr', dataIndex: 'WithdrawMethodStr' },
  { title: '备注', key: 'Remark', dataIndex: 'Remark' },
  { title: '创建时间', key: 'InsertDate', dataIndex: 'InsertDate' },
  { title: '金额', key: 'Amount', dataIndex:'Amount',slots: { customRender: 'amount' },},
  // { title: '原因', key: 'RejectReason', dataIndex: 'RejectReason' },
  { title: '操作', key: 'action', slots: { customRender: 'action' } },

];

export const walletType = [
  { code: 0, value: '线下提现' },
  { code: 1, value: '微信零钱' },
  // { code: 2, value: '银行卡' },
  // { code: 3, value: '支付宝余额' },
];
