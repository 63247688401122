
import { defineComponent } from 'vue';
import { Modal } from 'ant-design-vue';
import { useTable } from './list';
import AddCoupon from './component/add.vue';
import GiveUser from './component/giveUser.vue';
import GiveShop from './component/giveShop.vue';
import Price from '@/components/modules/Price.vue';

import UserRecord from '../record/user.vue';
import ShopRecord from '../record/shop.vue';

export default defineComponent({
  components: { AddCoupon, GiveUser, Price, GiveShop, Modal, UserRecord, ShopRecord },
  setup() {
    return {
      ...useTable(),
    };
  },
});
