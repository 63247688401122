import { reactive, ref, computed, watch } from 'vue';
import _ from 'lodash';
import { tableModel, searchModel, infoModel, giveUserModel, giveShopModel, giveStatus, couponType } from '@/data/modules/coupon/list';
import { message } from 'ant-design-vue';
import { addOrEditCoupon, couponList, couponDetail, giveCouponUser, giveCouponShop } from '@/api/coupon';
import { awaitWrap } from '@/util/index';
// import { useInitRouter } from '@/util/router';
import moment from 'moment';

interface Data {
  [key: string]: unknown;
}

interface SetupContext {
  attrs: Data;
  slots: any;
  emit: (event: string, ...args: unknown[]) => void;
}

//表单hooks
export function useTable(): any {
  // const { routerPush } = useInitRouter();
  //搜索数据
  const searchRef = reactive(_.clone(searchModel));

  //重置表单
  function handleReset() {
    _.assignIn(searchRef, searchModel);
  }

  //分页数据
  const pagination = reactive({
    current: 1,
    total: 10,
  });

  //表格数据
  const tableColumns = tableModel;
  const tableSource = ref([]);
  const tableLoading = ref(false);

  // 获取列表
  async function getList() {
    tableLoading.value = true;
    const { code, data } = await awaitWrap(couponList(searchRef));
    tableLoading.value = false;
    if (code === 200) {
      tableSource.value = data.data.DataList;
      pagination.total = data.data.RecordCount;
    }
  }

  //分页操作
  function handlePageChange(val: any) {
    pagination.current = val;
    searchRef.PageIndex = val;
    getList();
  }

  getList();

  //发放用户give
  const giveShow = ref(false);
  const couponId = ref(0);
  function handleGiveUser(el: any) {
    giveShow.value = true;
    couponId.value = el.Id;
  }

  //发放商家
  const giveShopShow = ref(false);
  function handleGiveShop(el: any) {
    giveShopShow.value = true;
    couponId.value = el.Id;
  }

  //编辑详情
  const addShow = ref(false);
  //编辑信息
  const editInfo = reactive({});
  //点击编辑方法 如果有数据覆盖否则默认
  function handldAddShow() {
    addShow.value = true;
    _.assign(editInfo, infoModel);
  }
  //编辑详情
  async function handleDetail(el: any) {
    const { code, data } = await awaitWrap(couponDetail(el.Id));
    if (code === 200) {
      _.assign(editInfo, data.data);
      addShow.value = true;
    }
  }

  //领取记录
  const recordShow = ref(false);
  const recordType = ref(0);
  function handleRecord(el: any) {
    recordShow.value = true;
    couponId.value = el.Id;
  }

  return {
    tableLoading,
    tableColumns,
    tableSource,
    pagination,
    searchRef,
    giveShow,
    couponType,
    handleGiveUser,
    handleDetail,
    handlePageChange,
    getList,
    handleReset,
    addShow,
    editInfo,
    couponId,
    handldAddShow,
    giveShopShow,
    handleGiveShop,
    recordShow,
    recordType,
    handleRecord,
  };
}

//发放用户hooks
export function useGiveUser(props: Data, cxt: SetupContext): any {
  //控制抽屉显示
  const showModel = computed({
    get() {
      return props.show;
    },
    set(val) {
      cxt.emit('update:show', val);
    },
  });

  //表单信息
  const infoReactive = reactive(_.clone(giveUserModel));
  // 表单ref
  const formRef = ref();
  //验证规则
  const infoRules = {
    UserMobile: [{ required: true, message: '请输人用户手机号', trigger: 'change' }],
  };

  //提交操作
  async function haeldSubmit(callback: () => void) {
    const validate = await awaitWrap(formRef.value.validate());
    if (validate.code === 200) {
      const ret = await awaitWrap(giveCouponUser(props.couponId, infoReactive));
      if (ret.code === 200) {
        message.success('发放成功');
        infoReactive.UserMobile = '';
        showModel.value = false;
        cxt.emit('confirm', {});
      }
    }
    callback();
  }

  return { showModel, giveStatus, infoReactive, infoRules, formRef, haeldSubmit };
}

//发放商家hooks
export function useGiveShop(props: Data, cxt: SetupContext): any {
  //控制抽屉显示
  const showModel = computed({
    get() {
      return props.show;
    },
    set(val) {
      cxt.emit('update:show', val);
    },
  });

  //表单信息
  const infoReactive = reactive(_.clone(giveShopModel));
  // 表单ref
  const formRef = ref();
  //验证规则
  const infoRules = {
    ShopId: [{ required: true, type: 'number', message: '请输人选择商家', trigger: 'change' }],
    GrantCount: [{ required: true, type: 'number', message: '请输人发放优惠券数量', trigger: 'change' }],
  };

  //提交操作
  async function haeldSubmit(callback: () => void) {
    const validate = await awaitWrap(formRef.value.validate());
    if (validate.code === 200) {
      const ret = await awaitWrap(giveCouponShop(props.couponId, infoReactive));
      if (ret.code === 200) {
        message.success('发放成功');
        _.assign(infoReactive, giveShopModel);
        showModel.value = false;

        cxt.emit('confirm', {});
      }
    }
    callback();
  }

  return { showModel, giveStatus, infoReactive, infoRules, formRef, haeldSubmit };
}

//添加优惠券hooks
export function useAdd(props: Data, cxt: SetupContext): any {
  //控制抽屉显示
  const showModel = computed({
    get() {
      return props.show;
    },
    set(val) {
      cxt.emit('update:show', val);
    },
  });

  //表单信息
  const infoReactive = reactive(_.clone(infoModel));

  watch(
    () => props.info,
    (val) => {
      _.assign(infoReactive, val);
    },
    { deep: true }
  );
  // 表单ref
  const formRef = ref();
  //验证固定时间
  const fixedDate = async (rule: any, value: number) => {
    if (!infoReactive.ValidType) {
      if (!value) {
        return Promise.reject('请选择固定时间段');
      }
      return Promise.resolve();
    }
    return Promise.resolve();
  };

  //验证天数
  const dayDate = async (rule: any, value: number) => {
    if (infoReactive.ValidType) {
      if (!value) {
        return Promise.reject('请选择输入有效天数');
      }
      return Promise.resolve();
    }
    return Promise.resolve();
  };

  //验证规则
  const infoRules = {
    CouponName: [{ required: true, message: '请输入优惠券名称', trigger: 'change' }],
    CouponAmount: [{ type: 'number', required: true, message: '请输入优惠券金额', trigger: 'change' }],
    Description: [{ required: true, message: '请输入说明', trigger: 'change' }],
    Summary: [{ required: true, message: '请输入简介', trigger: 'change' }],
    FixedStartDate: [{ validator: fixedDate, trigger: 'change' }],
    ValidDayCount: [{ validator: dayDate, trigger: 'change' }],
    SellPrice:[{ type: 'number', required: true, message: '请输入优惠券售卖金额', trigger: 'change'}]
  };

  //提交操作
  async function haeldSubmit(callback: () => void) {
    const validate = await awaitWrap(formRef.value.validate());
    if (validate.code === 200) {
      infoReactive.IsLowestSpend = infoReactive.LowestSpend ? 1 : 0;
      const ret = await awaitWrap(addOrEditCoupon(infoReactive));
      if (ret.code === 200) {
        message.success(infoReactive.Id ? '编辑成功' : '添加成功');
        showModel.value = false;
        cxt.emit('confirm', {});
      }
    }
    callback();
  }

  //计算属性转换时间为后台数据需要时间
  const orderTime = computed({
    get() {
      if (infoReactive.FixedStartDate) {
        return [moment(`${infoReactive.FixedStartDate}`, 'YYYY-MM-DD HH:mm:ss'), moment(`${infoReactive.FixedEndDate}`, 'YYYY-MM-DD HH:mm:ss')];
      }
      return [];
    },
    set(val: any) {
      if (val.length === 2) {
        infoReactive.FixedStartDate = val[0].format('YYYY-MM-DD HH:mm:ss');
        infoReactive.FixedEndDate = val[1].format('YYYY-MM-DD HH:mm:ss');
      }
    },
  });

  return { showModel, couponType, orderTime, infoReactive, infoRules, formRef, haeldSubmit };
}
