import request from './axios';

//添加商家信息
export const shopAdd = (data: any): Promise<any> => {
  return request({
    url: '/shop/info/add',
    method: 'post',
    data: data,
  });
};

//获取商家列表
export const shopList = (params: any): Promise<any> => {
  return request({
    url: '/shop/info/list',
    method: 'get',
    params: params,
  });
};

//获取商家详情
export const shopDetail = (id: any): Promise<any> => {
  return request({
    url: `/shop/info/${id}/detail`,
    method: 'get',
  });
};

//删除商家
export const shopDel = (id: any): Promise<any> => {
  return request({
    url: `/shop/info/${id}/del`,
    method: 'delete',
  });
};

//获取钱包明细
export const walletInfo = (params: any): Promise<any> => {
  return request({
    url: `/shop/wallet/detail`,
    method: 'get',
    params: params,
  });
};
