
import { defineComponent } from 'vue';
import { DatePicker } from 'ant-design-vue';
import { useAdd } from '../list';
import { infoModel } from '@/data/modules/coupon/list';
import moment from 'moment';
export default defineComponent({
  components: { ARangePicker: DatePicker.RangePicker },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default() {
        return infoModel;
      },
    },
  },
  setup(props, cxt) {
    return { moment, ...useAdd(props, cxt) };
  },
});
