
import { defineComponent, reactive, ref } from 'vue';
import _ from 'lodash';
import { applyListTableModel, applyListSearchModel, walletReviewType, walletType } from '@/data/modules/wallet/apply';
import { withdrawApplyList, withdrawApplyPassed, withdrawApplyReject } from '@/api/wallet';
import { awaitWrap } from '@/util/index';
import { ModalError } from '@/util/message';
import { message } from 'ant-design-vue';
import Price from '@/components/modules/Price.vue';
import { Modal, Popconfirm } from 'ant-design-vue';

export default defineComponent({
  components: { Price, AModal: Modal, APopconfirm: Popconfirm },
  setup() {
    //搜索数据
    const searchRef = reactive(_.clone(applyListSearchModel));

    //重置表单
    function handleReset() {
      _.assignIn(searchRef, applyListSearchModel);
    }

    //分页数据
    const pagination = reactive({
      current: 1,
      total: 10,
    });

    //表格数据
    const tableColumns = applyListTableModel;
    const tableSource = ref([]);
    const tableLoading = ref(false);

    // 获取列表
    async function getList() {
      tableLoading.value = true;
      const { code, data } = await awaitWrap(withdrawApplyList(searchRef));
      tableLoading.value = false;
      if (code === 200) {
        tableSource.value = data.data.DataList;
        pagination.total = data.data.RecordCount;
      }
    }

    //分页操作
    function handlePageChange(val: any) {
      pagination.current = val;
      searchRef.PageIndex = val;
      getList();
    }

    getList();

    //同意退款
    const withdrawMethod = ref(1);
    async function handleApplyPassed(el: any) {
      const { code } = await awaitWrap(withdrawApplyPassed(el.Id, withdrawMethod.value));
      return new Promise((resolve, reject) => {
        if (code === 200) {
          message.success('审核成功');
          getList();
          resolve('');
        } else {
          reject();
        }
      });
    }

    const rejectReason = ref('');
    const rejectId = ref(0);
    const RefuseVisible = ref(false);

    function handleApplyRefundShowBox(el: any) {
      rejectId.value = el.Id;
      RefuseVisible.value = true;
    }

    //同意拒绝
    async function handleRefundReject() {
      if (!rejectReason.value) {
        return message.warning('输入拒绝理由');
      }
      const { code } = await awaitWrap(withdrawApplyReject(rejectId.value, rejectReason.value));

      if (code === 200) {
        RefuseVisible.value = false;
        message.success('拒绝成功');
        getList();
      }
    }

    return {
      walletReviewType,
      withdrawMethod,
      walletType,
      tableLoading,
      tableColumns,
      tableSource,
      pagination,
      searchRef,
      handlePageChange,
      getList,
      handleReset,
      RefuseVisible,
      rejectReason,
      handleApplyRefundShowBox,
      handleRefundReject,
      rejectId,
      handleApplyPassed,
    };
  },
});
