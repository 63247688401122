
import { defineComponent, ref } from 'vue';

import NpcWallet from '@/views/npc/walletInfo/walletInfo.vue';
import MerchantWallet from '@/views/merchant/components/walletInfo.vue';

export default defineComponent({
  components: { NpcWallet, MerchantWallet },
  setup() {
    const activeKey = ref('1');
    return { activeKey };
  },
});
