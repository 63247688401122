export const tableModel = [
  { title: '优惠券类型', dataIndex: 'CouponTypeStr', slots: { customRender: 'CouponTypeStr' }, key: 'CouponTypeStr' },
  { title: '优惠券名称', dataIndex: 'CouponName', key: 'CouponName' },
  { title: '优惠券数量', dataIndex: 'CouponCount', slots: { customRender: 'CouponCount' }, key: 'CouponCount' },
  { title: '金额', dataIndex: 'CouponAmount', slots: { customRender: 'CouponAmount' }, key: 'CouponAmount' },
  { title: '用户限制领取', key: 'CouponLimit', slots: { customRender: 'CouponLimit' }, dataIndex: 'CouponLimit' },
  { title: '最低消费', key: 'LowestSpend', slots: { customRender: 'LowestSpend' }, dataIndex: 'LowestSpend' },
  { title: '销售价', key: 'SellPrice', slots: { customRender: 'SellPrice' } },
  { title: '操作', key: 'action', slots: { customRender: 'action' }, width: '200px', fixed: 'right' },
];

export const searchModel = {
  IsLowestSpend: '',
  CouponType: '',
  CouponName: '',
  PageIndex: 1,
  PageSize: 10,
};

export const infoModel = {
  CouponAmount: '',
  CouponCount: 100,
  CouponLimit: 1,
  CouponName: '',
  CouponType: 0,
  Description: '',
  Id: 0,
  IsSell:0,
  SellPrice:0,
  IsLowestSpend: 0,
  LowestSpend: 0,
  Summary: '',
  ValidDayCount: 0,
  ValidStartDate: 0,
  FixedEndDate: '',
  FixedStartDate: '',
  ValidType: 0, // 有效期类型(0:固定日期，1：动态日期)
};

export const giveUserModel = {
  UserMobile: '',
};

export const giveShopModel = {
  ShopId: null,
  GrantCount: null,
};

export const couponType = [
  { code: 0, value: '小时券' },
  { code: 1, value: '满减券' },
  { code: 2, value: '折扣券' },
];

export const giveStatus = [
  { code: 0, value: '老用户' },
  { code: 1, value: '新用户' },
];
