import { reactive, ref, watch } from 'vue';
import _ from 'lodash';
import { userSearchModel, userTableModel, shopTableModel, shopSearchModel } from '@/data/modules/coupon/record';
import { couponUserList, couponShopList } from '@/api/coupon';
import { awaitWrap } from '@/util/index';

//用户领取列表hooks
export function useUser(props: any, ctx: any): any {
  //搜索数据
  const searchRef = reactive(_.clone(userSearchModel));

  searchRef.CouponRuleId = props.couponId;

  watch(
    () => props.couponId,
    (val) => {
      searchRef.CouponRuleId = val;
      getList();
    }
  );

  //重置表单
  function handleReset() {
    _.assignIn(searchRef, userSearchModel);
  }

  //分页数据
  const pagination = reactive({
    current: 1,
    total: 10,
  });

  //表格数据
  const tableColumns = userTableModel;
  const tableSource = ref([]);
  const tableLoading = ref(false);

  // 获取列表
  async function getList() {
    tableLoading.value = true;
    const { code, data } = await awaitWrap(couponUserList(searchRef));
    tableLoading.value = false;
    if (code === 200) {
      tableSource.value = data.data.DataList;
      ``;
      pagination.total = data.data.RecordCount;
    }
  }

  //分页操作
  function handlePageChange(val: any) {
    pagination.current = val;
    searchRef.PageIndex = val;
    getList();
  }

  getList();

  return { searchRef, handleReset, pagination, handlePageChange, tableColumns, tableLoading, tableSource, getList };
}

//商家领取列表hooks
export function useShop(props: any, ctx: any): any {
  //搜索数据
  const searchRef = reactive(_.clone(shopSearchModel));
  searchRef.CouponRuleId = props.couponId;

  watch(
    () => props.couponId,
    (val) => {
      searchRef.CouponRuleId = val;
      getList();
    }
  );

  //重置表单
  function handleReset() {
    _.assignIn(searchRef, shopSearchModel);
  }

  //分页数据
  const pagination = reactive({
    current: 1,
    total: 10,
  });

  //表格数据
  const tableColumns = shopTableModel;
  const tableSource = ref([]);
  const tableLoading = ref(false);

  // 获取列表
  async function getList() {
    tableLoading.value = true;
    const { code, data } = await awaitWrap(couponShopList(searchRef));
    tableLoading.value = false;
    if (code === 200) {
      tableSource.value = data.data.DataList;
      ``;
      pagination.total = data.data.RecordCount;
    }
  }

  //分页操作
  function handlePageChange(val: any) {
    pagination.current = val;
    searchRef.PageIndex = val;
    getList();
  }

  getList();

  return { searchRef, handleReset, pagination, handlePageChange, tableColumns, tableLoading, tableSource, getList };
}
