import request from './axios';


// 提现申请列表
export const withdrawApplyList = (params:any) => {
  return request({
    url: '/wallet/withdraw/list',
    method: 'get',
    params: params,
  });
};

// 提现申请拒绝
export const withdrawApplyReject = (id:any,RejectReason:any) => {
  return request({
    url: `/wallet/withdraw/${id}/reject`,
    method: 'put',
    data: {RejectReason},
  });
};

// 提现申请通过
export const withdrawApplyPassed= (id:any,WithdrawMethod:any) => {
  return request({
    url: `/wallet/withdraw/${id}/passed`,
    method: 'put',
    data:{
      WithdrawMethod
    }
  });
};
