import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_textarea = _resolveComponent("a-textarea")
  const _component_a_form_item = _resolveComponent("a-form-item")
  const _component_a_form = _resolveComponent("a-form")
  const _component_drawer_box = _resolveComponent("drawer-box")

  return (_openBlock(), _createBlock(_component_drawer_box, {
    title: "发放优惠券",
    width: "600px",
    visible: _ctx.showModel,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModel = $event)),
    onDrawerSubmit: _ctx.haeldSubmit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        layout: "vertical",
        ref: "formRef",
        model: _ctx.infoReactive,
        rules: _ctx.infoRules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: "输入手机号",
            name: "UserMobile"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_textarea, {
                value: _ctx.infoReactive.UserMobile,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.infoReactive.UserMobile = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["visible", "onDrawerSubmit"]))
}