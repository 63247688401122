export const userTableModel = [
  { title: '优惠券类型', dataIndex: 'CouponTypeStr', slots: { customRender: 'CouponTypeStr' }, key: 'CouponTypeStr' },
  { title: '优惠券名称', dataIndex: 'CouponName', key: 'CouponName' },
  { title: '用户手机号', dataIndex: 'UserMobile', slots: { customRender: 'UserMobile' }, key: 'UserMobile' },
  { title: '发放方', dataIndex: 'IssuerTypeStr', slots: { customRender: 'IssuerTypeStr' }, key: 'IssuerTypeStr' },
  { title: '发放人', dataIndex: 'IssuerName', slots: { customRender: 'IssuerName' }, key: 'IssuerName' },
  { title: '开始时间', dataIndex: 'ValidStartDate', slots: { customRender: 'ValidStartDate' }, key: 'ValidStartDate' },
  { title: '结束时间', dataIndex: 'ValidEndDate', slots: { customRender: 'ValidEndDate' }, key: 'ValidEndDate' },
  // { title: '结束时间', key: 'action', slots: { customRender: 'action' }, width: '350px', fixed: 'right' },
];

export const userSearchModel = {
  CouponRuleId: '',
  UserMobile: '',
  PageIndex: 1,
  PageSize: 10,
};


export const shopTableModel = [
  // { title: '优惠券类型', dataIndex: 'CouponTypeStr', slots: { customRender: 'CouponTypeStr' }, key: 'CouponTypeStr' },
  { title: '商家名称', dataIndex: 'ShopName', key: 'ShopName' },
  { title: '优惠券名称', dataIndex: 'CouponName', key: 'CouponName' },
  { title: '发放时间', dataIndex: 'InsertDate', slots: { customRender: 'InsertDate' }, key: 'InsertDate' },
  { title: '优惠券总数', dataIndex: 'ShopCouponCount', slots: { customRender: 'ShopCouponCount' }, key: 'ShopCouponCount' },
  { title: '发放数量', dataIndex: 'ShopGrantCount', slots: { customRender: 'ShopGrantCount' }, key: 'ShopGrantCount' },
  { title: '剩余优惠券数量', dataIndex: 'leftover', slots: { customRender: 'leftover' } },
];

export const shopSearchModel = {
  CouponRuleId: '',
  ShopId: '',
  PageIndex: 1,
  PageSize: 10,
};
