
import { defineComponent } from 'vue';
import { useGiveShop } from '../list';
import { useTable } from '@/views/merchant/list';
export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    couponId: {
      type: [String, Number],
      default: 'false',
    },
  },
  setup(props, cxt) {
    const { searchRef, getList, tableSource } = useTable();

    function handleSearchShop(value: string) {
      searchRef.ShopName = value;
      getList();
      console.log(tableSource.value);
    }
    return { searchRef, tableSource, handleSearchShop, ...useGiveShop(props, cxt) };
  },
});
